import { useState } from "react";
import Wrapper from "components/Wrapper";
import Button from "components/Button";

import imgLogo from "assets/images/logo.svg";
import imgBg from "assets/images/bg.jpg";
import imgHeadline from "assets/images/headline-intro.svg";
import imgHeadlineSub from "assets/images/headline-sub.svg";

const User = ({ startQuiz, name, setName }) => {
  const [isError, setIsError] = useState(false);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const clickHandler = (e) => {
    /*     e.preventDefault();
    if (name === "") {
      alert("NI");
    } else {
      startQuiz();
    } */
    startQuiz();
  };

  return (
    <>
      <Wrapper className="align-items-center justify-content-center">
        <div>
          <div className="logo ">
            <img src={imgLogo} />
          </div>
          <div>
            <img className="headline" src={imgHeadlineSub} />
          </div>
          <div className="mt-5 ">
            <p className="fw-bold">Najprej nam zaupaj svoje ime.</p>
            <p>
              Da vemo, komu gledamo v oči (in srce), <br />
              ko mu na koncu voščimo.
            </p>
          </div>
          <div className="name">
            <div className="input-group input-group-lg">
              <form className=" mt-3 w-100">
                <input
                  type="text"
                  className="form-control w-100"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  onChange={handleChange}
                  value={name}
                  placeholder="Vpiši svoje ime"
                />

                <Button
                  className={`mt-3 ${name === "" && "disabled"}`}
                  onClick={clickHandler}
                  label="START"
                />
              </form>
            </div>
          </div>
          <div className="mt-5"></div>
        </div>
      </Wrapper>
    </>
  );
};

export default User;
