import React, { useState, useEffect, useRef } from "react";
import "assets/styles/style.scss";
import { motion, AnimatePresence } from "framer-motion";
import useSound from "use-sound";
import Snowfall from "react-snowfall";

import quizApi from "data/apiQuestions.json";
import quizProducts from "data/apiProducts.json";
import filesound1 from "assets/sounds/bell_icon_short.mp3";
import filesound2 from "assets/sounds/disco_icon_short.mp3";
import filesound3 from "assets/sounds/guitar_icon_short.mp3";

import User from "views/User";
import Intro from "views/Intro";
import Questions from "views/Questions";
import Results from "views/Results";

export default function App() {
  // When true intro will be displayed
  const [showIntro, setShowIntro] = useState(true);

  const [showUser, setShowUser] = useState(false);
  // When true results will be displayed
  const [showProducts, setShowProducts] = useState(false);
  // Recommended products showPreloader
  const [showPreloader, setShowshowPreloader] = useState(false);
  // Current question ID (we use id from API instead of index)
  const [currentQuestion, setCurrentQuestion] = useState(1);
  // Array of products that are determined by user answer choice
  const [chosenProducts, setchosenProducts] = useState([]);
  // Array of chosen answers
  const [chosenAnswer, setChosenAnswer] = useState([]);
  // Get first category (moški/ženska) for further styling
  const [categoryClass, setCategoryClass] = useState("");

  const [name, setName] = useState("");

  const enterName = () => {
    setShowIntro(false);
    setShowUser(true);
    setShowProducts(false);
  };

  const startQuiz = (e) => {
    setShowUser(false);
    setShowProducts(false);
    setCurrentQuestion(1);
    setChosenAnswer([]);
    stopAudio1();
    stopAudio2();
    stopAudio3();
  };

  const [playAudio1, { stop: stopAudio1, sound: sound1 }] = useSound(
    filesound1,
    {
      volume: 0.5,
    }
  );
  const [playAudio2, { stop: stopAudio2, sound: sound2 }] = useSound(
    filesound2,
    {
      volume: 0.5,
    }
  );

  const [playAudio3, { stop: stopAudio3, sound: sound3 }] = useSound(
    filesound3,
    {
      volume: 0.5,
    }
  );

  const handleToFinish = (sound) => {
    console.log(sound);

    setShowshowPreloader(true);
    setTimeout(() => {
      setShowshowPreloader(false);
      setShowProducts(true);
      if (sound === 0) {
        playAudio1();
        sound1.fade(0, 1, 5000);
      }

      if (sound === 50) {
        playAudio2();
        sound2.fade(0, 1, 5000);
      }

      if (sound === 100) {
        playAudio3();
        sound3.fade(0, 1, 5000);
      }
    }, 2500);
  };

  const handleAnswerOptionClick = (path, productTags, answerText) => {
    const nextQuestion = path;

    // Add product to array based on chosen answer
    if (productTags !== null) {
      setchosenProducts((current) => [...current, productTags]);
    }

    // Check whether next question exists otherwise show results
    if (nextQuestion <= quizApi.content.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowshowPreloader(true);
      setTimeout(() => {
        setShowshowPreloader(false);
        setShowProducts(true);
      }, 1000);
    }
    /* 
    if (productTags === "music1") {
      playAudio1();
    }

    if (productTags === "music2") {
      playAudio2();
    }

    if (productTags === "music3") {
      playAudio3();
    }
 */
    setChosenAnswer((current) => [...current, answerText]);
  };

  // Find object of current question
  const filterCurrentQuestion = quizApi.content.find(
    (obj) => obj.id === currentQuestion
  );

  // Check if we have 100% productTags match
  const filterProductsEvery = quizProducts.filter((item) => {
    let results = chosenProducts.every((tag) => {
      return item.productTags.includes(tag);
    });

    return results;
  });

  // Check if we have more than 1 productTags match
  const filterProductsSome = quizProducts.filter((item) => {
    var result = chosenProducts.filter((n) => {
      return item.productTags.includes(n);
    });

    if (result.length > 1) {
      //console.log("result", result.length);
      return result;
    }
  });

  // If 100% match show that otherwise fallback to at least 1 match
  const filterProducts = () => {
    if (filterProductsEvery.length > 0) {
      return filterProductsEvery;
    } else {
      return filterProductsSome;
    }
  };

  return (
    <AnimatePresence>
      <div className="quiz d-flex justify-content-center">
        {showProducts ? (
          <Results
            setShowIntro={setShowIntro}
            filterProducts={filterProducts}
            categoryClass={categoryClass}
            startQuiz={startQuiz}
            name={name}
          />
        ) : showIntro ? (
          <Intro enterName={enterName} />
        ) : showUser ? (
          <User startQuiz={startQuiz} name={name} setName={setName} />
        ) : (
          <Questions
            showPreloader={showPreloader}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            filterCurrentQuestion={filterCurrentQuestion}
            handleAnswerOptionClick={handleAnswerOptionClick}
            categoryClass={categoryClass}
            handleToFinish={handleToFinish}
          />
        )}

        {/* for dev, temp*/}
        <div className="d-none dev-filtered-products">
          <div>
            {chosenProducts.map((item, index) => {
              return <span key={index}>{item}</span>;
            })}
          </div>
          <div>
            {filterProducts().map((item, index) => {
              return <span key={index}>{item.productName}</span>;
            })}
          </div>
        </div>
      </div>
      <Snowfall
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
        }}
      />
    </AnimatePresence>
  );
}
