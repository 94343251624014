function Button({ onClick, className, label }) {
  const clickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button className={`btn main-button  ${className}`} onClick={clickHandler}>
      <span>{label}</span>
    </button>
  );
}

export default Button;
