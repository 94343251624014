import { motion } from "framer-motion";

import { dropIn, fadeInOut, testNew } from "utils/animations";

const Wrapper = ({ children, className }) => {
  return (
    <motion.div
      className={`wrapper p-4 d-flex  text-center ${className}  `}
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit">
      {children}
    </motion.div>
  );
};

export default Wrapper;
