import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { fadeInOut, containerVariants, child } from "utils/animations.js";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import Preloader from "components/Preloader.js";
import Progressbar from "components/Progressbar.js";

import imgLogo from "assets/images/logo.svg";
import imgHeadlineSub from "assets/images/headline-sub.svg";

const marks = {
  0: {
    label: <strong>0</strong>,
  },
  50: {
    label: <strong>50</strong>,
  },
  100: {
    label: <strong>100</strong>,
  },
};

const Questions = ({
  showPreloader,
  currentQuestion,
  setCurrentQuestion,
  filterCurrentQuestion,
  handleAnswerOptionClick,
  handleToFinish,
  categoryClass,
}) => {
  const [pickedSound, setPickedSound] = useState("");

  function log(value) {
    setPickedSound(value);
  }

  return (
    <Wrapper className="justify-content-center">
      {showPreloader && <Preloader />}
      <div>
        <div className="logo ">
          <img src={imgLogo} />
        </div>
        <div>
          <img className="headline mini pt-5" src={imgHeadlineSub} />
        </div>
        <Progressbar currentQuestion={currentQuestion} />
        <div className="quiz-content h-100 mt-0">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              className="h-100"
              key={currentQuestion}
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="exit">
              <div className="h-100 d-flex align-items-end align-items-center flex-column">
                <div>
                  <motion.p
                    variants={child}
                    className="mt-4 mb-3 mt-sm-5 fw-bold">
                    {filterCurrentQuestion.questionText}
                  </motion.p>
                  <motion.p variants={child} className=" mb-4 px-2 ">
                    {filterCurrentQuestion.questionDescription}
                  </motion.p>

                  {currentQuestion === 3 ? (
                    <div>
                      <Slider
                        min={0}
                        marks={marks}
                        step={null}
                        onChange={log}
                        included={false}
                        defaultValue={50}
                      />
                      <Button
                        className="mt-7"
                        onClick={() => handleToFinish(pickedSound)}
                        label="Ustvari voščilo"
                      />
                    </div>
                  ) : (
                    <div>
                      {filterCurrentQuestion.answerOptions.map(
                        (answerOption, index) => (
                          <motion.div
                            className="d-inline"
                            key={index}
                            variants={child}>
                            <button
                              className={`btn btn-question p-options text-center p-0 mt-1 mb-1 mx-2  ${
                                answerOption.answerImg
                                  ? "image-answer"
                                  : "text-answer"
                              }`}
                              onClick={() => {
                                handleAnswerOptionClick(
                                  answerOption.path,
                                  answerOption.productTags,
                                  answerOption.answerText
                                );
                              }}>
                              {/* Show image if question answerImg true */}
                              {answerOption.answerImg ? (
                                <>
                                  <div className=" d-flex align-items-center justify-content-center">
                                    <img src={answerOption.answerImg} />
                                  </div>
                                  {answerOption.answerText.length !== 0 && (
                                    <p
                                      className="small text-center mt-2 mb-0 mx-auto"
                                      style={{ width: "60px" }}>
                                      {answerOption.answerText}
                                    </p>
                                  )}
                                </>
                              ) : (
                                <span>{answerOption.answerText}</span>
                              )}
                            </button>
                          </motion.div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
              {currentQuestion > 1 && (
                <button
                  onClick={() => setCurrentQuestion(currentQuestion - 1)}
                  className="btn btn-back text-white">
                  <i className="bi bi-arrow-left"></i>&nbsp;Nazaj
                </button>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </Wrapper>
  );
};

export default Questions;
