import Lottie from "lottie-react";
import animationPreload from "utils/89450-christmas-tree-loading.json";

const showPreloader = () => {
  return (
    <div className="preloader d-flex align-items-center justify-content-center">
      <div>
        <h3 className="h3-loader mb-3 text-turq">Izdelujem čestitko...</h3>
        <div className="loading-animation d-flex justify-content-center align-items-center mb-2">
          <Lottie
            className="w-50 mx-auto"
            animationData={animationPreload}
            loop={false}
          />
        </div>
      </div>
    </div>
  );
};

export default showPreloader;
