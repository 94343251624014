import { motion } from "framer-motion";
import { LinkedinShareButton, FacebookShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon } from "react-share";
import {
  fadeInOut,
  containerVariants,
  child,
  flyIn,
} from "utils/animations.js";
import Button from "components/Button";

import imgStarSnow from "assets/images/icon_snowstar.svg";
import imgBraun from "assets/images/logo-braun.svg";

const ShowResults = ({
  setShowIntro,
  filterProducts,
  categoryClass,
  startQuiz,
  name,
}) => {
  return (
    <>
      <motion.div
        variants={flyIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={`wrapper results  d-flex bg-transparent ${categoryClass}`}>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible">
          {filterProducts()
            .filter((item, index) => index === 0)
            .map((item, index) => {
              return (
                <div key={index} className="result-card">
                  <div className="name" style={{ color: item.color }}>
                    {name},
                  </div>
                  <img className="w-100 " src={item.productImg} />
                </div>
              );
            })}
        </motion.div>

        <div className="button-bottom">
          <LinkedinShareButton url="http://ny23.escapebox.si/">
            <LinkedinIcon className="me-2" size="25" round="true" />
          </LinkedinShareButton>
          <Button onClick={(e) => startQuiz(e)} label="Reši še enkrat" />
          <FacebookShareButton url="http://ny23.escapebox.si/">
            <FacebookIcon className="ms-2" size="25" round="true" />
          </FacebookShareButton>
        </div>
      </motion.div>
    </>
  );
};

export default ShowResults;
