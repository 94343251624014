import Wrapper from "components/Wrapper";
import Button from "components/Button";

import imgLogo from "assets/images/logo.svg";
import imgBg from "assets/images/bg.jpg";
import imgHeadline from "assets/images/headline-intro.svg";
import imgHeadlineSub from "assets/images/headline-sub.svg";

const Intro = ({ enterName }) => {
  return (
    <>
      <Wrapper className="align-items-center justify-content-center">
        <div>
          <div className="logo ">
            <img src={imgLogo} />
          </div>
          <div>
            <img className="headline" src={imgHeadlineSub} />
          </div>
          <div className="mt-5 fw-bold container-narrow">
            <p>
              Popolno novoletno voščilo ne obstaja. RAZEN ... če si ga ustvariš
              sam/-a.
            </p>

            <p>
              In danes je tvoj srečen dan, saj ti bomo to omogočili. Popolnoma
              po tvojih željah. Popolnoma zate.
            </p>
          </div>

          <div className="mt-5">
            <Button onClick={enterName} label="Ustvari voščilo" />
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Intro;
